import React, { FC } from "react";
import styled from "styled-components";
import { Image } from "../../types";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import Link from "../shared/Link";

export type ToolsToGoBeyondProps = {
  isPublished?: boolean;
  title: string;
  cards: CardType[];
};

type CardType = {
  id: string;
  title: string;
  picture: Image;
  link: string;
};

const ToolsToGoBeyond: FC<ToolsToGoBeyondProps> = ({
  isPublished,
  title,
  cards,
}: ToolsToGoBeyondProps) => {
  if (!isPublished || !cards?.length) return null;

  return (
    <Container>
      <Title>{title}</Title>
      <CardContainer>
        {cards?.map((card) => (
          <Card key={card?.id} to={card?.link}>
            <PictureWrapper>
              <Picture src={card?.picture?.src} alt={card?.picture?.alt} />
            </PictureWrapper>
            <CardTitle>{card?.title}</CardTitle>
          </Card>
        ))}
      </CardContainer>
    </Container>
  );
};

const Container = styled.section`
  background-color: #1e4320;
  padding: ${toVWMobile(51)}vw ${toVWMobile(19)}vw ${toVWMobile(40)}vw;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(93)}vw ${toVWDesktop(100)}vw ${toVWDesktop(80)}vw;
  }
`;

const Title = styled.h3`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(44)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.012em;
  line-height: ${toVWMobile(53)}vw;
  margin-bottom: ${toVWMobile(153)}vw;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(60)}rem;
    letter-spacing: 0.03em;
    line-height: ${toVWDesktop(64)}vw;
    margin-bottom: ${toVWDesktop(63)}vw;
    text-align: center;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ${toVWDesktop(10)}vw;
  }
`;

const Card = styled(Link)`
  background-color: #012102;
  padding: 0 ${toVWMobile(20)}vw ${toVWMobile(20)}vw;
  text-decoration: none;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: ${toVWMobile(153)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: unset;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(100)}vw;
    padding: 0 ${toVWDesktop(25)}vw ${toVWDesktop(40)}vw;
    width: ${toVWDesktop(400)}vw;
  }
`;

const PictureWrapper = styled.div`
  height: ${toVWMobile(178)}vw;
  margin-bottom: ${toVWMobile(29)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(272)}vw;
    margin-bottom: ${toVWDesktop(49)}vw;
  }
`;

const Picture = styled.img`
  height: ${toVWMobile(289)}vw;
  object-fit: contain;
  position: relative;
  top: ${toVWMobile(-111)}vw;
  width: 100%;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(352)}vw;
    object-fit: cover;
    top: ${toVWDesktop(-80)}vw;
  }
`;

const CardTitle = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.08)}rem;
  line-height: ${toVWMobile(40)}vw;
  margin-bottom: ${toVWMobile(13)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;
  width: 80%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    letter-spacing: ${toREM(0.72)}rem;
    line-height: ${toVWDesktop(40)}vw;
    margin-bottom: ${toVWDesktop(14)}vw;
    width: 100%;
  }
`;

export default ToolsToGoBeyond;
