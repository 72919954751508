import React, { FC } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

import Link from "../Link";

import RightArrow from "../../../assets/images/carousel_right_arrow.svg";
import LeftArrow from "../../../assets/images/carousel_left_arrow.svg";

import {
  toVWMobile,
  toVWDesktop,
  vwMobile,
  vwDesktop,
  rem,
} from "../../../helpers/styles";
import { BREAKPOINTS } from "../../../helpers/theme";

export type TextLinkProps = {
  link: string;
  label: string | undefined;
  color?: string | undefined;
  isRTL?: boolean | undefined;
};

const TextLink: FC<TextLinkProps> = ({
  link = "",
  label = "",
  color = "#000000",
  isRTL = false,
}: TextLinkProps) => {
  return (
    <TextLinkContainer to={link} color={color} isRTL={isRTL}>
      <span>{label}</span>
      <Arrow isRTL={isRTL} src={isRTL ? LeftArrow : RightArrow} color={color} />
    </TextLinkContainer>
  );
};

const TextLinkContainer = styled(Link)<{ color: string, isRTL: boolean }>`
  display: inline-block;
  height: ${vwMobile(24)};
  direction: ${({ isRTL }) => (isRTL ? 'rtl' : 'ltr')};
  border-width: 0;
  border-style: solid;
  border-color: ${(props) => props?.color};
  background: transparent;
  color: ${(props) => props?.color};
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  font-weight: 800;
  letter-spacing: 0.01em;
  text-decoration: none;
  transition: all 0.2s;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${vwDesktop(24)};
  }

  &:hover {
    cursor: pointer;
    border-bottom-width: ${vwMobile(4)};

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      border-bottom-width: ${vwDesktop(4)};
    }
  }
`;

const Arrow = styled(SVG)<{ color: string, isRTL: boolean }>`
  margin: ${({ isRTL }) => (isRTL ? `0 ${toVWMobile(8)}vw 0 0` : `0 0 0 ${toVWMobile(8)}vw`)};
  width: ${toVWMobile(20)}vw;
  height: ${toVWMobile(11)}vw;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: ${({ isRTL }) => (isRTL ? `0 ${toVWDesktop(8)}vw 0 0` : `0 0 0 ${toVWDesktop(8)}vw`)};
    width: ${toVWDesktop(20)}vw;
    height: ${toVWDesktop(11)}vw;
  }

  & path {
    fill: ${(props) => props?.color};
  }
`;

export default TextLink;
