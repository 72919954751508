import React, { FC } from "react";
import styled from "styled-components";

import ButtonLink, { ButtonType } from "../shared/ButtonLink";
import {
  toVWMobile,
  toVWDesktop,
  toREM,
  vwDesktop,
} from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { Image } from "../../types";

import Download from "../../assets/images/download.svg";
import { useStaticLabels } from "../../hooks/useStaticLabels";

export type ExploreProductsProps = {
  isPublished?: boolean;
  title: string;
  cardList: Card[];
  className?: string;
};

type Card = {
  title: string;
  description: string;
  image: Image;
  downloadBtn: { label: string; link: string };
  viewBtn: { label: string; link: string };
};

const ExploreProducts: FC<ExploreProductsProps> = ({
  isPublished,
  title,
  cardList,
  className,
}: ExploreProductsProps) => {
  const labels = useStaticLabels();

  if (!isPublished || !cardList?.length) return null;

  return (
    <Container className={className}>
      <Title>{title}</Title>
      <CardWrapper>
        {cardList?.map((card, index) => (
          <Card key={index}>
            <PictureWrapper>
              <Picture src={card?.image?.src} alt={card?.image?.alt} />
            </PictureWrapper>
            <TextWrapper>
              <CardTitle
                dangerouslySetInnerHTML={{
                  __html: card?.title,
                }}
              />
              <Description>{card?.description}</Description>
            </TextWrapper>

            <ButtonWrapper>
              <DownloadBtn
                type={ButtonType.PRIMARY_LIGHT}
                label={card?.downloadBtn?.label}
                iconComponent={<Icon src={Download} alt={labels?.download} />}
                link={card?.downloadBtn?.link}
                target={"_blank"}
              />
              <SellBtn
                type={ButtonType.SECONDARY_LIGHT}
                label={card?.viewBtn?.label}
                link={card?.viewBtn?.link}
              />
            </ButtonWrapper>
          </Card>
        ))}
      </CardWrapper>
    </Container>
  );
};

const Container = styled.div`
  padding: ${toVWMobile(40)}vw ${toVWMobile(20)}vw ${toVWMobile(38)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 ${toVWDesktop(100)}vw ${toVWDesktop(80)}vw;
  }
`;

const Title = styled.h4`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(44)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.012em;
  line-height: ${toVWMobile(53)}vw;
  margin-bottom: ${toVWMobile(153)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(60)}rem;
    letter-spacing: 0.03em;
    line-height: ${toVWDesktop(64)}vw;
    margin-bottom: ${toVWDesktop(163)}vw;
    text-align: center;
  }
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${toVWMobile(151)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    grid-template-columns: repeat(3, ${vwDesktop(400)});
    gap: ${toVWDesktop(120)}vw ${toVWDesktop(20)}vw;
  }
`;

const Card = styled.div`
  background-color: #012102;
  padding: 0 ${toVWMobile(20)}vw ${toVWMobile(20)}vw;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 ${toVWDesktop(25)}vw ${toVWDesktop(40)}vw;
  }
`;

const PictureWrapper = styled.div`
  height: ${toVWMobile(178)}vw;
  margin-bottom: ${toVWMobile(20)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(272)}vw;
    margin-bottom: ${toVWDesktop(41)}vw;
  }
`;

const Picture = styled.img`
  height: ${toVWMobile(289)}vw;
  object-fit: contain;
  position: relative;
  top: ${toVWMobile(-111)}vw;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(352)}vw;
    object-fit: cover;
    top: ${toVWDesktop(-80)}vw;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(109)}vw;
    margin-bottom: ${toVWDesktop(30)}vw;
  }
`;

const CardTitle = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(35)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.03em;
  line-height: ${toVWMobile(40)}vw;
  margin-bottom: ${toVWMobile(8)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;
  width: 80%;

  strong {
    font-weight: inherit;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    letter-spacing: 0.02em;
    line-height: ${toVWDesktop(40)}vw;
    margin-bottom: ${toVWDesktop(10)}vw;
    width: 100%;
  }
`;

const Description = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(28)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(24)}vw;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${toVWMobile(16)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    justify-content: space-between;
    gap: ${toVWDesktop(10)}vw;
  }
`;

const Icon = styled.img`
  width: ${toVWMobile(40)}vw;
  height: ${toVWMobile(40)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(40)}vw;
    height: ${toVWDesktop(40)}vw;
  }
`;

const DownloadBtn = styled(ButtonLink)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  height: ${toVWMobile(56)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(56)}vw;
    width: ${toVWDesktop(166)}vw;
    min-width: 1px;
    padding: ${toVWDesktop(19)}vw ${toVWDesktop(20)}vw ${toVWDesktop(19)}vw
      ${toVWDesktop(30)}vw;
  }
`;

const SellBtn = styled(ButtonLink)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  height: ${toVWMobile(56)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(56)}vw;
    width: ${toVWDesktop(166)}vw;
    min-width: 1px;
  }
`;

export default ExploreProducts;
