import { graphql } from "gatsby";
import { useIntl } from "../hooks/useIntl";
import React, { useCallback } from "react";
import styled from "styled-components";
import ExploreProducts, {
  ExploreProductsProps,
} from "../components/ExploreProducts";
import MainCarousel, { MainCarouselProps } from "../components/MainCarousel";
import Page from "../components/Page";
import PartnersReviews, {
  PartnersReviewsProps,
} from "../components/PartnersReviews";
import StartSelling, {
  StartSellingFormLabels,
  StartSellingFormProps,
  StartSellingInfoProps,
} from "../components/StartSelling";
import Tabs from "../components/Tabs";
import ToolsToGoBeyond, {
  ToolsToGoBeyondProps,
} from "../components/ToolsToGoBeyond";
import QuestionsAndAnswers, {
  QuestionsAndAnswersProps,
} from "../components/QuestionsAndAnswers";

import { getAssetURL } from "../helpers/assets";
import { getTranslations } from "../helpers/translations";
import { toVWDesktop, toVWMobile } from "../helpers/styles";
import { BREAKPOINTS } from "../helpers/theme";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import { isPublished } from "../helpers/directus";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { ROUTES } from "../helpers/routes";

const SellLandingPage = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getRootTranslatedData = useCallback(() => {
    return (
      getTranslations(
        data,
        "directus.sell_landing_page.translations",
        intl.locale
      ) ?? {}
    );
  }, [data]);

  const getMainCarouselData = useCallback((): MainCarouselProps => {
    const translatedData = getRootTranslatedData();

    return {
      isPublished: isPublished(translatedData?.hero_carousel_status),
      title: translatedData?.hero_carousel_title,
      slides: translatedData?.hero_carousel_slides
        ?.map((slide: any) => {
          const translatedSlide = getTranslations(
            slide,
            "sell_landing_main_carousel_slides_id.translations",
            intl.locale
          );
          return {
            id: translatedSlide?.id,
            image: {
              src: getAssetURL(translatedSlide?.image),
              alt: translatedSlide?.image_alt,
            },
            title: translatedData?.hero_carousel_whats_new,
            description: translatedSlide?.title,
            status: translatedSlide?.status,
            button: {
              label: labels?.learnMore,
              link: translatedSlide?.learn_more_link,
            },
          };
        })
        ?.filter(({ status }: { status: string }) => isPublished(status)),
    };
  }, [getRootTranslatedData, intl]);

  const getToolsToGoBeyondData = useCallback((): ToolsToGoBeyondProps => {
    const translatedData = getRootTranslatedData();

    const cardTitles = {
      0: labels?.partnerMarketingGuide,
      1: labels?.beyondMeatBrandAssets,
      2: labels?.pop,
    };

    const translatedPartners = translatedData?.partner_tools_cards
      ?.map((card: any, index: number) => {
        const { image, image_alt, status, button_link } =
          getTranslations(
            card,
            "tools_to_go_beyond_card_id.translations",
            intl.locale
          ) ?? {};
        return {
          status,
          picture: {
            src: getAssetURL(image),
            alt: image_alt,
          },
          title: cardTitles[index],
          link: button_link,
        };
      })
      ?.filter(({ status }: { status: string }) => isPublished(status));

    return {
      isPublished: isPublished(translatedData?.partner_tools_status),
      title: labels?.toolsToGoBeyond,
      cards: translatedPartners,
    };
  }, [getRootTranslatedData, intl]);

  const getLetsTalkBeyondData = useCallback((): PartnersReviewsProps => {
    const t = getRootTranslatedData();

    return {
      title: t?.reviews_title,
      cardList: isPublished(t?.reviews_status)
        ? t?.reviews_partners_opinion?.map((review: any) => ({
            quote: review?.partner_quote,
            name: review?.partner_name,
            job_role: review?.partner_job_role,
          }))
        : [],
    };
  }, [getRootTranslatedData, intl]);

  const getExploreOurProductsData = useCallback((): ExploreProductsProps => {
    const t = getRootTranslatedData();

    return {
      isPublished: isPublished(t?.our_products_status),
      title: t?.our_product_title,
      cardList: t?.our_products_sell_pages
        ?.map((card: any) => {
          const { product_status, product_detail } =
            getTranslations(card, "sell_page_id.translations", intl.locale) ??
            {};

          const { variants, sell_sheet, description, sell_page_content } =
            getTranslations(product_detail, "translations", intl.locale) ?? {};

          const translatedVariant = variants
            ?.map(
              (variant: any) =>
                getTranslations(
                  variant,
                  "product_variant_id.translations",
                  intl.locale
                ) ?? {}
            )
            ?.find(({ status }: { status: string }) => isPublished(status));

          const translatedSellPageContent = getTranslations(
            sell_page_content,
            "translations",
            intl.locale
          ) ?? {};

          return {
            status: product_status,
            title: translatedSellPageContent.food_service_card_title ?? translatedVariant?.title,
            description: translatedSellPageContent.food_service_card_description ?? description,
            image: {
              src: getAssetURL(translatedSellPageContent.food_service_card_image ?? translatedVariant?.large_image),
              alt: translatedVariant?.large_image_alt,
            },
            downloadBtn: {
              label: labels?.download,
              link: getAssetURL(sell_sheet),
            },
            viewBtn: {
              label: labels?.viewSellPage,
              link: `${ROUTES.SELL_DETAIL}/${card?.sell_page_id?.sell_page_slug}`,
            },
          };
        })
        ?.filter(({ status }: { status: string }) => isPublished(status)),
    };
  }, [getRootTranslatedData]);

  const getQuestionsData = useCallback((): QuestionsAndAnswersProps | null => {
    const translatedData = getRootTranslatedData();
    if (!isPublished(translatedData?.faq_status)) return null;

    return {
      title: translatedData?.faq_title,
      subtitle: translatedData?.faq_subtitle,
      button: {
        label: labels?.viewAllFaqs,
        link: ROUTES.FAQS,
      },
      cards: translatedData?.faq_cards?.map(
        (card: { title: string; description: string }) => ({
          title: card.title,
          description: card.description,
        })
      ),
    };
  }, [getRootTranslatedData, intl]);

  const getStartSellingData = useCallback((): StartSellingInfoProps &
    StartSellingFormProps => {
    const translatedData = getRootTranslatedData();

    const partnerForm: StartSellingFormLabels =
      getTranslations(
        translatedData,
        "lets_talk_form.translations",
        intl.locale
      ) ?? {};

    const translatedTabsSelling = translatedData?.tabs_selling_descriptions
      ?.map((tab: any) =>
        getTranslations(
          tab,
          "partner_with_beyond_left_tab_section_id.translations",
          intl.locale
        )
      )
      ?.filter(({ status }: { status: string }) => isPublished(status));

    return {
      isTabPublished: isPublished(translatedData?.tabs_status),
      leftTabLabel: translatedData?.left_tab_title,
      rightTabLabel: translatedData?.right_tab_title,
      selling_description: translatedTabsSelling,
      tabsSubtitle: translatedData?.tabs_subtitle,
      partner_with_beyond_form: isPublished(translatedData?.lets_talk_status)
        ? {
            title: partnerForm?.title,
            description: partnerForm?.description,
            successMessage: partnerForm?.success_message,
            klaviyoListId: partnerForm?.klaviyo_id,
            first_step: {
              tab_form_title: partnerForm?.left_tab_title,
              country_placeholder: labels?.countryPlaceholder,
              first_name_placeholder: labels?.firstNamePlaceholder,
              last_name_placeholder: labels?.lastNamePlaceholder,
              business_name_placeholder: labels?.businessNamePlaceholder,
              city_placeholder: labels?.cityPlaceholder,
              state_province_placeholder: labels?.stateProvincePlaceholder,
              button_link: "",
              button_label: partnerForm?.left_tab_button_label,
            },
            second_step: {
              tab_form_title: partnerForm?.right_tab_title,
              email_placeholder: labels?.emailPlaceholder,
              phone_placeholder: labels?.phonePlaceholder,
              which_product_placeholder: partnerForm?.which_product_placeholder,
              which_distributors_placeholder:
                partnerForm?.which_distributors_placeholder,
              disclaimer: partnerForm?.disclaimer,
              left_button_label: partnerForm?.right_tab_secondary_button_label,
              left_button_link: partnerForm?.right_tab_secondary_button_link,
              right_button_label: partnerForm?.right_tab_primary_button_label,
              right_button_link: partnerForm?.right_tab_primary_button_link,
            },
          }
        : null,
    };
  }, [getRootTranslatedData]);

  const {
    leftTabLabel = "",
    rightTabLabel = "",
    isTabPublished,
    ...rest
  } = getStartSellingData();

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={<SEO {...parseSEO(getRootTranslatedData(), intl?.locale)} />}
    >
      <MainCarousel {...getMainCarouselData()} />
      {isTabPublished && (
        <StyledTabs
          tabs={[
            {
              id: "start_selling",
              title: leftTabLabel,
              children: (
                <>
                  <StartSelling {...rest} />
                  <ExploreProducts {...getExploreOurProductsData()} />
                  <PartnersReviews {...getLetsTalkBeyondData()} />
                </>
              ),
            },
            {
              id: "already_a_partner",
              title: rightTabLabel,
              children: (
                <>
                  <StyledExploreProducts {...getExploreOurProductsData()} />
                  <ToolsToGoBeyond {...getToolsToGoBeyondData()} />
                </>
              ),
            },
          ]}
        />
      )}
      <QuestionsAndAnswers {...getQuestionsData()} />
    </Page>
  );
};

export default SellLandingPage;

const StyledExploreProducts = styled(ExploreProducts)`
  padding: ${toVWMobile(0)}vw ${toVWMobile(20)}vw ${toVWMobile(38)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 ${toVWDesktop(100)}vw ${toVWDesktop(80)}vw;
  }
`;

const StyledTabs = styled(Tabs)`
  & > .tab-container {
    overflow-x: auto;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      overflow-x: visible;
    }

    & .tab {
      margin-right: ${(props) =>
        props.theme.isRTL ? 0 : `${toVWDesktop(20)}vw`};

      @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
        margin-right: 0;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
`;

export const query = graphql`
  query ($locale: String) {
    directus {
      sell_landing_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          languages_code {
            code
            name
          }
          status
          hero_carousel_status
          hero_carousel_title
          hero_carousel_whats_new
          hero_carousel_slides {
            sell_landing_main_carousel_slides_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                id
                status
                title
                learn_more_link
                image_alt
                image {
                  id
                  filename_disk
                }
              }
            }
          }
          tabs_status
          left_tab_title
          right_tab_title
          tabs_subtitle
          tabs_selling_descriptions {
            partner_with_beyond_left_tab_section_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                status
                title
                description
                image_alt
                image {
                  id
                  filename_disk
                }
              }
            }
          }
          lets_talk_status
          lets_talk_form {
            translations(
              filter: { languages_code: { code: { _eq: $locale } } }
            ) {
              languages_code {
                code
                name
              }
              form_label
              title
              klaviyo_id
              description
              success_message
              left_tab_title
              left_tab_button_label
              right_tab_title
              disclaimer
              which_product_placeholder
              which_distributors_placeholder
              right_tab_primary_button_label
              right_tab_primary_button_link
              right_tab_secondary_button_label
              right_tab_secondary_button_link
            }
          }
          our_products_status
          our_product_title
          our_products_sell_pages {
            sell_page_id {
              sell_page_slug
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                product_status
                product_detail {
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    product_name
                    sell_sheet {
                      id
                      filename_disk
                    }
                    sell_page_content {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        food_service_card_title
                        food_service_card_description
                        food_service_card_image {
                            description
                            filename_disk
                            id
                        }
                      }
                    }
                    description
                    variants {
                      product_variant_id {
                        variant_slug
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          status
                          title
                          description
                          large_image_alt
                          large_image {
                            id
                            filename_disk
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          reviews_status
          reviews_title
          reviews_partners_opinion
          partner_tools_status
          partner_tools_cards {
            tools_to_go_beyond_card_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                status
                image_alt
                button_link
                image {
                  id
                  filename_disk
                }
              }
            }
          }
          faq_status
          faq_title
          faq_subtitle
          faq_cards
        }
      }
    }
  }
`;
