import React, { ReactElement } from "react";
import styled from "styled-components";
import { getAssetURL } from "../../helpers/assets";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import Form, { StartSellingFormLabels } from "./Form";

export type SellingDescription = {
  image: string;
  image_alt: string;
  description: string;
};

export type StartSellingInfoProps = {
  tabsSubtitle: string;
  isTabPublished?: boolean;
  leftTabLabel?: string;
  rightTabLabel?: string;
  selling_description: SellingDescription[];
};
export type StartSellingFormProps = {
  partner_with_beyond_form: StartSellingFormLabels | null;
};
export const StartSelling = (
  props: StartSellingInfoProps & StartSellingFormProps
): ReactElement => {
  const {
    selling_description: info,
    partner_with_beyond_form: formLabels,
    tabsSubtitle,
  } = props;

  return (
    <Container>
      <FormContainer>{formLabels && <Form {...formLabels} />}</FormContainer>
      <Info>
        <Title dangerouslySetInnerHTML={{ __html: tabsSubtitle }} />
        {info?.map((item, i) => (
          <div key={item.title + i}>
            <Img src={getAssetURL(item.image)} alt={item.image_alt} />
            <Heading>{item.title}</Heading>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        ))}
      </Info>
    </Container>
  );
};

const Img = styled.img`
  height: ${vwMobile(75)};
  width: ${vwMobile(72)};
  object-fit: contain;
  ${MEDIA_DESKTOP} {
    height: ${vwDesktop(75)};
    transform: scale(1);
    width: ${vwDesktop(72)};
  }
`;

const Info = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${vwMobile(45)};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(400)};
    gap: ${vwDesktop(45)};
    padding-top: ${vwDesktop(0)};
    padding-bottom: ${vwDesktop(140)};
  }

  ul {
    display: flex;
    flex-flow: column;
    gap: ${vwMobile(12)};
    padding-left: ${(props) => (props.theme.isRTL ? 0 : `${vwMobile(16)}`)};
    padding-right: ${(props) => (props.theme.isRTL ? `${vwMobile(16)}` : 0)};

    ${MEDIA_DESKTOP} {
      gap: ${vwDesktop(12)};
      padding-left: ${(props) => (props.theme.isRTL ? 0 : `${vwDesktop(16)}`)};
      padding-right: ${(props) => (props.theme.isRTL ? `${vwDesktop(16)}` : 0)};
    }
  }

  li,
  p {
    font-family: ${(props) => props.theme.fontFamily},
      ${(props) => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    font-weight: 600;
  }

  h3,
  h4 {
    margin-block: ${vwMobile(22)};
    font-family: ${(props) => props.theme.fontFamily},
      ${(props) => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${rem(24)};
    line-height: ${rem(31)};
    font-weight: 900;
    letter-spacing: 0.06em;
    ${MEDIA_DESKTOP} {
      margin-block: ${vwDesktop(15)};
    }
  }
`;

const Title = styled.h2`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(36)};
  line-height: ${rem(40)};
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  ${MEDIA_DESKTOP} {
    font-size: ${rem(56)};
    line-height: ${rem(64)};
  }
`;

const Heading = styled.h3<{ color?: string }>`
  color: ${(p) => p.color ?? "#75c154"};
  font-size: ${rem(24)};
  font-weight: 900;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  line-height: ${vwMobile(29)};

  ${MEDIA_DESKTOP} {
    line-height: ${vwDesktop(29)};
    width: 90%;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(80)};

  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 600;
  color: white;

  background-color: #1e4320;
  padding: ${vwMobile(40)} ${vwMobile(19)};
  padding-top: 0;

  ${MEDIA_DESKTOP} {
    flex-direction: row-reverse;
    justify-content: center;
    gap: ${vwDesktop(125)};
    padding: 0 ${vwDesktop(100)} ${vwDesktop(80)} ${vwDesktop(205)};
  }
`;

const FormContainer = styled.div``;

export default StartSelling;
