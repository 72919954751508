import React, { FC, useRef, useState, useMemo } from "react";
import "slick-carousel/slick/slick.css";
import styled, { css } from "styled-components";

import { Image } from "../../types";
import {
  toVWMobile,
  toVWDesktop,
  toREM,
  vwMobile,
  vwDesktop,
  rem,
} from "../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../helpers/theme";
import PlayArrowButton, {
  PlayButtonRef,
} from "../shared/PlayArrowButton/PlayArrowButton";
import AnimatedCarouselSlide from "../shared/AnimatedCarouselSlide";
import AnimatedCarousel from "../shared/AnimatedCarousel";
import TextLink from "../shared/TextLink";
import useRightToLeft from "../../hooks/useRightToLeft";

export type MainCarouselProps = {
  isPublished: boolean;
  title: string;
  slides: Slide[];
};

type Slide = {
  id: string;
  image: Image;
  title: string;
  description: string;
  button: { label: string; link: string };
};

const MAIN_IMAGE_CAROUSEL_ID = "MAIN_IMAGE_CAROUSEL";
const MAIN_TEXT_CAROUSEL_ID = "MAIN_TEXT_CAROUSEL";

const getizeRatio = (text: string) => {
  if (text?.length > 10) {
    return 0.75;
  }
  return 1;
};

const MainCarousel: FC<MainCarouselProps> = ({
  isPublished,
  title,
  slides = [],
}: MainCarouselProps) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [reverse, setReverse] = useState(false);
  const playBtn = useRef<PlayButtonRef>();
  const isRTL = useRightToLeft();

  if (!isPublished) return null;

  const mainImageAnimation = useMemo(() => {
    return {
      from: { transform: `translateX(100%)`, opacity: 0 },
      enter: { transform: `translateX(0)`, opacity: 1 },
      leave: { delay: 1000, opacity: 0 },
    };
  }, []);

  const mainImageAnimationReverse = useMemo(() => {
    return {
      from: { transform: `translateX(-100%)`, opacity: 0 },
      enter: { transform: `translateX(0)`, opacity: 1 },
      leave: { delay: 1000, opacity: 0 },
    };
  }, []);

  const textAnimation = useMemo(() => {
    return {
      from: { opacity: 0, transform: `translateX(50%)` },
      enter: { opacity: 1, transform: `translateX(0)` },
      leave: {
        opacity: 0,
      },
    };
  }, []);

  const textAnimationReverse = useMemo(() => {
    return {
      from: { opacity: 0, transform: `translateX(-50%)` },
      enter: { opacity: 1, transform: `translateX(0)` },
      leave: {
        opacity: 0,
      },
    };
  }, []);

  const handleNext = () => {
    setReverse(false);
    setActiveSlide((prev) => {
      return prev === slides?.length - 1 ? 0 : prev + 1;
    });
    playBtn.current?.reset();
  };

  const handlePrev = () => {
    setReverse(true);
    setActiveSlide((prev) => {
      return prev === 0 ? slides?.length - 1 : prev - 1;
    });
    playBtn.current?.reset();
  };

  return (
    <Container>
      <LeftContent>
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </LeftContent>

      <RightContent>
        <CardWrapper>
          <Picture>
            <MainImageCarousel
              id={MAIN_IMAGE_CAROUSEL_ID}
              aria-live="off"
              elements={slides?.map((slide, i) => (
                <AnimatedSlide
                  key={`slideImg-${i}`}
                  show={activeSlide === i}
                  animationConfig={
                    reverse ? mainImageAnimationReverse : mainImageAnimation
                  }
                  active={activeSlide === i}
                >
                  <img src={slide.image.src} alt={slide.image.alt} />
                </AnimatedSlide>
              ))}
            />

            <DetailBox>
              <DetailContent>
                <MainImageCarousel
                  id={MAIN_TEXT_CAROUSEL_ID}
                  aria-live="off"
                  elements={slides?.map((slide, i) => (
                    <AnimatedSlide
                      key={`slideImg-${i + 2}`}
                      show={activeSlide === i}
                      animationConfig={
                        reverse ? textAnimationReverse : textAnimation
                      }
                      active={activeSlide === i}
                    >
                      <SmallText>{slide?.title}</SmallText>
                      <BigText ratio={getizeRatio(slide?.description)}>
                        {slide?.description}
                      </BigText>
                      <TextLink
                        isRTL={isRTL}
                        link={slide?.button?.link}
                        label={slide?.button?.label}
                      />
                    </AnimatedSlide>
                  ))}
                />
              </DetailContent>
              {slides?.length > 1 && (
                <ArrowWrapper>
                  <PlayArrowButton
                    aria-controls={`${MAIN_IMAGE_CAROUSEL_ID} ${MAIN_TEXT_CAROUSEL_ID}`}
                    ref={playBtn}
                    borderColor={"#ffffff"}
                    bgColor={"#75C154"}
                    animated
                    onClick={handleNext}
                    onAnimationEnd={handleNext}
                  />
                  <PlayArrowButton
                    aria-controls={`${MAIN_IMAGE_CAROUSEL_ID} ${MAIN_TEXT_CAROUSEL_ID}`}
                    borderColor={"#ffffff"}
                    bgColor={"#75C154"}
                    onClick={handlePrev}
                    rotate
                  />
                </ArrowWrapper>
              )}
            </DetailBox>
          </Picture>
        </CardWrapper>
      </RightContent>
    </Container>
  );
};

const AnimatedSlide = styled(AnimatedCarouselSlide)<{
  active: boolean;
}>`
  z-index: ${(props) => (props.active ? 2 : 0)};

  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const MainImageCarousel = styled(AnimatedCarousel)`
  width: 100%;
  height: 100%;
`;

const Container = styled.section`
  background-color: #033305;
  display: flex;
  flex-direction: column;
  height: ${vwMobile(757)};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    height: ${vwDesktop(617.5)};
  }
`;

const LeftContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${toVWMobile(40)}vw ${toVWMobile(19)}vw ${toVWMobile(57)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    align-items: flex-start;
    width: 50%;
    padding: ${(props) =>
      props.theme.isRTL
        ? `${toVWDesktop(122)}vw ${toVWDesktop(205)}vw
            ${toVWDesktop(151)}vw ${toVWDesktop(20)}vw`
        : `${toVWDesktop(122)}vw ${toVWDesktop(20)}vw ${toVWDesktop(151)}vw
      ${toVWDesktop(205)}vw`};
  }
`;

const Title = styled.h1`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  line-height: ${rem(52)};
  text-transform: uppercase;
  font-size: ${toREM(48)}rem;
  letter-spacing: 0.03em;

  strong {
    color: #75c154;
    display: inline;
    font-weight: inherit;
    ${MEDIA_DESKTOP} {
      display: block;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    letter-spacing: 0.03em;
    font-size: ${toREM(80)}rem;
    line-height: ${toVWDesktop(88)}vw;
    width: ${vwDesktop(505)};
  }
`;

const RightContent = styled.div`
  padding-bottom: ${vwMobile(133)};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 50%;
    padding-bottom: 0;
  }
`;

const CardWrapper = styled.div`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: 100%;
    width: ${toVWDesktop(610)}vw !important;
  }
`;

const Picture = styled.div`
  height: ${toVWMobile(375)}vw;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(610)};
    height: ${toVWDesktop(570)}vw;
  }
`;

const DetailBox = styled.div`
  display: flex;
  background-color: #ffffff;
  padding: ${vwMobile(24)} ${vwMobile(40)} 0;
  position: absolute;
  left: 0;
  right: ${vwMobile(35)};
  bottom: ${vwMobile(-118)};
  height: ${vwMobile(173)};
  z-index: 3;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    left: ${vwDesktop(36)};
    right: ${vwDesktop(35)};
    bottom: ${vwDesktop(-47.5)};
    height: ${vwDesktop(186)};
    padding: ${toVWDesktop(28)}vw ${toVWDesktop(28)}vw 0;
  }
`;

const DetailContent = styled.div`
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-right: ${(props) =>
      props.theme.isRTL ? `${toVWDesktop(20)}vw` : 0};
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(20.9)};
  position: absolute;

  top: ${vwMobile(29.4)};
  right: ${toVWMobile(-22)}vw;

  ${MEDIA_DESKTOP} {
    top: ${vwDesktop(29.4)};
    gap: ${vwDesktop(20.9)};
    right: ${toVWDesktop(-22)}vw;
  }
`;

const SmallText = styled.h2`
  height: ${vwMobile(19)};
  margin-bottom: ${vwMobile(4)};
  color: #7a3818;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 800;
  font-style: normal;
  letter-spacing: 0.08em;
  line-height: ${rem(28)};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${vwDesktop(19)};
    margin-bottom: ${toVWDesktop(9)}vw;
  }
`;

const BigText = styled.h3<{ ratio: number }>`
  display: block;
  margin-bottom: ${vwMobile(17)};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${(props) => props.ratio * toREM(24)}rem;
  line-height: ${rem(26)};
  letter-spacing: 0.04em;
  font-weight: 900;
  color: #000000;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    min-height: ${vwDesktop(52.5)};
    margin-bottom: ${vwDesktop(10.5)};
    font-size: ${(props) => props.ratio * toREM(32)}rem;
    line-height: ${rem(42)};
    letter-spacing: 0.02em;
  }
`;

export default MainCarousel;
